import React, { useEffect } from 'react'
import { Link, useParams, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { fetchColor } from '../rtk/slices/color-slice';
import { useTranslation } from 'react-i18next';
import { fetchProjects } from '../rtk/slices/projects-slice';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';


// import required modules
import { Autoplay, Pagination } from 'swiper/modules';
import { fetchProducts } from '../rtk/slices/products-slice';


function Service () {
    window.scrollTo(0, 0); // العودة إلى الجزء العلوي
    const { id } = useParams();
    const { t, i18n } = useTranslation();

    const total30 = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30]

    const navigate = useNavigate();
    // توجيه المستخدم إلى القسم "Contact"
    const handleButtonClick = () => {
        navigate('/#contact');
    };

    const dispatch = useDispatch();
    const products = useSelector((state) => state.products.products);
    const color = useSelector((state) => state.color.color);

    const products_en = products.map(ser_en => {
        if (i18n.language == "en") {
            return ({
                "id": ser_en.id,
                "img_service": ser_en.img_service,
                "keyword": ser_en.keyword_en,
                "whats_service": ser_en.whats_service_en,
                "img_project1": ser_en.img_project1,
                "img_project2": ser_en.img_project2,
                "img_project3": ser_en.img_project3,
                "img_project4": ser_en.img_project4,
                "img_project5": ser_en.img_project5,
                "img_project6": ser_en.img_project6,
                "img_project7": ser_en.img_project7,
                "img_project8": ser_en.img_project8,
                "img_project9": ser_en.img_project9,
                "img_project10": ser_en.img_project10,
                "img_project11": ser_en.img_project11,
                "img_project12": ser_en.img_project12,
                "img_project13": ser_en.img_project13,
                "img_project14": ser_en.img_project14,
                "img_project15": ser_en.img_project15,
                "img_project16": ser_en.img_project16,
                "img_project17": ser_en.img_project17,
                "img_project18": ser_en.img_project18,
                "img_project19": ser_en.img_project19,
                "img_project20": ser_en.img_project20,
                "img_project21": ser_en.img_project21,
                "img_project22": ser_en.img_project22,
                "img_project23": ser_en.img_project23,
                "img_project24": ser_en.img_project24,
                "img_project25": ser_en.img_project25,
                "img_project26": ser_en.img_project26,
                "img_project27": ser_en.img_project27,
                "img_project28": ser_en.img_project28,
                "img_project29": ser_en.img_project29,
                "img_project30": ser_en.img_project30,

            })
        }
        return ser_en;
    })

    if (color.length > 0) {
        let inputString = color[0].color;
        let filteredValue = inputString.replace(/[^0-9,]/g, '');

        // تعيين قيمة المتغير --main-color في :root
        const root = document.documentElement;
        root.style.setProperty("--main-color", `${filteredValue}`);
    }


    useEffect(() => {
        if (sessionStorage.getItem("lang")) {
            i18n.changeLanguage(sessionStorage.getItem("lang"))
        }
        if (sessionStorage.getItem("lang") === "en") {
            document.body.style.direction = "ltr";
        } else {
            document.body.style.direction = "rtl";
        }
        // fetch color
        dispatch(fetchColor());

        // fetch products
        dispatch(fetchProducts());

    }, [])

    return (
        <section className='projects service ' id='projects'>
            <div className="container">
                <h2 className="title">
                    <span>{i18n.language == "ar" ? "المنتجات" : "Products"}</span>
                    <p style={{ textTransform: "capitalize" }}>{i18n.language == "ar" ? "المنتجات" : "Products"}</p>
                </h2>


                {products_en.map((service) => {
                    return (
                        service['id'] == id ?
                            <div className="card-service">

                                <Swiper
                                    effect={'coverflow'}
                                    grabCursor={true}
                                    centeredSlides={true}
                                    slidesPerView={'auto'}
                                    coverflowEffect={{
                                        rotate: 0,
                                        stretch: 0,
                                        depth: 100,
                                        modifier: 1,
                                        slideShadows: true,
                                    }}
                                    autoplay={{
                                        delay: 2500,
                                        disableOnInteraction: false,
                                    }}
                                    initialSlide={2}
                                    pagination={true}
                                    modules={[Pagination, Autoplay]}
                                    className="mySwiper"

                                >

                                    {
                                        total30.map((number) => {
                                            const imgKey = `img_project${number}`;
                                            const captionKey = `caption_img_project${number}`;

                                            if (service[imgKey] && service[imgKey].length > 4) {
                                                return (
                                                    <SwiperSlide key={service.id}>
                                                        <div key={number} className="testimonials-item">
                                                            <img style={{ maxWidth: '18rem', maxHeight: '18rem' }} src={`${process.env.REACT_APP_PROJECTS_API}assest/imgs/${service[imgKey]}`} alt="" />
                                                            <h1>{service[captionKey]}</h1>
                                                        </div>
                                                    </SwiperSlide>
                                                );
                                            } else {
                                                return null;
                                            }
                                        })
                                    }

                                </Swiper>


                                <div className="content">
                                    <div className="box">
                                        <h3>{i18n.language == "ar" ? `${service.keyword} ` : `${service.keyword} `} </h3>
                                        <p>
                                            {service.whats_service.split(',').map((what) => {
                                                return (
                                                    <>
                                                        <span>{what}</span><br />
                                                    </>
                                                )
                                            })}
                                        </p>
                                        {/* <p>
                                            {service.prices_service.split(',').map((what) => {
                                                return (
                                                    <>
                                                        <span className='priceSpan'>{what}</span><br />
                                                    </>
                                                )
                                            })}
                                        </p> */}
                                    </div>
                                    <div style={{ textAlign: "center" }} class="btns"><button class="button type1"><span class="btn-txt"><a target='_blank' href={`https://api.whatsapp.com/send?phone=201090509500&text=Al Magd Website: ${service.keyword}`}>{i18n.language == "ar" ? "اطلب الأن" : "Order Now"}</a></span></button></div>

                                    {/* <div className="box">
                                        <h3>المميزات</h3>
                                        <p>
                                            {service.features_service.split(',').map((feature) => {
                                                return (
                                                    <>
                                                        <span>{feature}</span><br />
                                                    </>
                                                )
                                            })}
                                        </p>
                                    </div>
                                    <div className="box">
                                        <h3>الاسعار</h3>
                                        <p className='priceP'>
                                            {service.prices_service.split(',').map((price) => {
                                                return (
                                                    <>
                                                        <span className='priceSpan'>{price}</span><br />
                                                    </>
                                                )
                                            })}
                                        </p>
                                    </div> */}
                                    {/* <div style={{ textAlign: "center" }} class="btns"><button class="button type1"><span class="btn-txt"><Link to={`/contact`}>اطلب الان</Link></span></button></div> */}
                                </div>
                            </div>
                            : null
                    )
                })}

                <Link className='exit' to={`/`}>
                    <div class="menu active">
                        <span class="span1"></span>
                        <span class="span2"></span>
                        <span class="span3"></span>
                    </div>
                </Link>
            </div>
        </section>
    )
}

export default Service
