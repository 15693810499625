import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import show1 from '../assest/des1.jpg'
import show2 from '../assest/des3.jpg'
import show3 from '../assest/des2.jpg'
import show4 from '../assest/des4.jpg'
import show5 from '../assest/des5.jpg'
import show6 from '../assest/des6.jpg'
import { useDispatch, useSelector } from 'react-redux'
import { fetchServices } from '../rtk/slices/services-slice'
import { useTranslation } from 'react-i18next'
import { fetchProjects } from '../rtk/slices/projects-slice'
import { fetchProducts } from '../rtk/slices/products-slice'

function Certificates () {
    const dispatch = useDispatch();
    const services = useSelector((state) => state.products.products);
    const { t, i18n } = useTranslation();

    const services_en = services.map(ser_en => {
        if (i18n.language == "en") {
            return ({
                "id": ser_en.id,
                "img_service": ser_en.img_service,
                "keyword": ser_en.keyword_en,
            })
        }
        return ser_en;
    })

    useEffect(() => {
        // fetch show
        dispatch(fetchProducts());
    }, [])

    return (
        <section className='certificates products' id='products'>
            <div className="contact-menu">
                <a target="_blank" href="https://api.whatsapp.com/send?phone=201093658356&text=Al Magd Website: Hi" class="whatsapp_link">
                </a>
            </div>

            <div className="container">
                <h2 data-aos="fade-left" className="title">
                    <span>{i18n.language == "ar" ? "المنتجات" : "Products"}</span>
                    <p>{i18n.language == "ar" ? "المنتجات" : "Products"}</p>
                </h2>
                <div className="cards">
                    {services_en.map((card) => {
                        return (
                            <Link to={`/service/${card.id}`} data-aos="zoom-out-up" className="card" key={card.id}>
                                <img src={`${process.env.REACT_APP_PROJECTS_API}assest/imgs/${card.img_service}`} alt="" />
                                <h4 style={{ paddingTop: "1rem" }}>{card.keyword}</h4>
                            </Link>
                        )
                    })}
                </div>
            </div>
        </section>
    )
}

export default Certificates
