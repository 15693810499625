
import All from "./components/All";
// import All_certificates from "./components/All_certificates";

import { BrowserRouter as Router, Route, Routes, BrowserRouter } from 'react-router-dom';
import Service from "./components/Service";
import Blog from "./components/Blog";
import All_Blogs from "./components/All_Blogs";
import All_certificates from "./components/All_certificates";


function App () {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<All />} />
          <Route path="/projects" element={<All_certificates />} />
          <Route path="/service/:id" element={<Service />} />
          <Route path="/blogs" element={<All_Blogs />} />
          <Route path="/blogs/blog/:id" element={<Blog />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
